import {
	DownOutlined,
	QuestionCircleOutlined,
	RetweetOutlined,
} from '@ant-design/icons';
import {
	Button,
	Card,
	Dropdown,
	Modal,
	Popconfirm,
	Tooltip,
	Typography,
} from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import get from 'lodash/get';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { USER_ROLES } from 'src/_shared/constants';
import {
	calculateReferralBonus,
	conditionalStatusStyle,
	formatDate,
	lambda,
	mapReferralStatus,
	ml,
} from 'src/_shared/services/utils.js';
import { COLORS } from 'src/_shared/styles/colors.js';
import { JobLocation, MyReferralsBonusDetailsModal } from '..';
import note from '../../assets/note.png';
import { ReferralHiredModal } from '../../referral-hired-modal';
import { ProgressIndicator } from '../referral-progress-indicator';
import OwnershipPeriodTooltip from './OwnershipPeriodTooltip.jsx';
import ReferralEditModal from './ReferralEditModalComponent.jsx';
import { referralCardKeys } from './referralCardKeys.js';

const { Paragraph, Text } = Typography;

class ReferralCardJobComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hiredModalVisible: false,
			dropdownVisibility: false,
			status() {
				let ref = get(props, 'referral.job.referrals', []);
				ref = ref.find((r) => r.id === get(props, 'referral.id'));
				if (ref?.length > 0) {
					return get(ref, 'status');
				}
			},
			bonusStatus: get(props, ['referral', 'bonusStatus']),
			referral: get(props, 'referral'),
			visibleBonusDetails: false,
			ml_BonusDetails: '',
			ml_Job: '',
			ml_ReferredCandidate: '',
			ml_TotalBonus: '',
			ml_HiredDate: '',
			ml_StartDate: '',
			ml_BonusPayments: '',
			ml_BonusNotes: '',
			ml_Close: '',
			ml_ViewBonusDetails: '',
			resendSuccess: false,
			translatedTitle: '',
			visibleReferralModal: false,
		};
	}

	async componentDidMount() {
		const { referral } = this.props;
		const jobTitle = get(referral, 'job.title');
		const newTitle = jobTitle;

		this.setState({
			translatedTitle: newTitle,
		});
		if (this.props.allMultiLingualData.length > 0) {
			this.props.setMultiLingualData(this.props.allMultiLingualData);
			const languageCode =
				this.props.currentUser.languageCode === null
					? 'US'
					: this.props.currentUser.languageCode;
			const filteredData = this.props.allMultiLingualData.filter(
				(item) => item.languageCode === languageCode
			);
			const bonusDetail = filteredData.find(
				(item) => item.key === referralCardKeys.BonusDetails
			);
			const job = filteredData.find(
				(item) => item.key === referralCardKeys.Job
			);
			const referredCandidate = filteredData.find(
				(item) => item.key === referralCardKeys.ReferredCandidate
			);
			const totalBonus = filteredData.find(
				(item) => item.key === referralCardKeys.TotalBonus
			);
			const hiredDate = filteredData.find(
				(item) => item.key === referralCardKeys.HiredDate
			);
			const startDate = filteredData.find(
				(item) => item.key === referralCardKeys.StartDate
			);
			const bonusPayment = filteredData.find(
				(item) => item.key === referralCardKeys.BonusPayments
			);
			const bonusNote = filteredData.find(
				(item) => item.key === referralCardKeys.BonusNotes
			);
			const close = filteredData.find(
				(item) => item.key === referralCardKeys.Close
			);
			const viewBonusDetail = filteredData.find(
				(item) => item.key === referralCardKeys.ViewBonusDetails
			);
			this.setState({
				ml_BonusDetails: get(bonusDetail, 'text', 'Bonus Details'),
				ml_Job: get(job, 'text', 'Job'),
				ml_ReferredCandidate: get(
					referredCandidate,
					'text',
					'Referred Candidate'
				),
				ml_TotalBonus: get(totalBonus, 'text', 'Total Bonus'),
				ml_HiredDate: get(hiredDate, 'text', 'Hired Date'),
				ml_StartDate: get(startDate, 'text', 'Start Date'),
				ml_BonusPayments: get(bonusPayment, 'text', 'Bonus Payments'),
				ml_BonusNotes: get(bonusNote, 'text', 'Bonus Notes'),
				ml_Close: get(close, 'text', 'Close'),
				ml_ViewBonusDetails: get(viewBonusDetail, 'text', 'View Bonus Details'),
			});
		} else {
			this.setState({
				ml_BonusDetails: 'Bonus Details',
				ml_Job: 'Job',
				ml_ReferredCandidate: 'Referred Candidate',
				ml_TotalBonus: 'Total Bonus',
				ml_HiredDate: 'Hired Date',
				ml_BonusPayments: 'Bonus Payments',
				ml_BonusNotes: 'Bonus Notes',
				ml_Close: 'Close',
				ml_ViewBonusDetails: 'View Bonus Details',
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.currentUser.languageCode !== this.props.currentUser.languageCode
		) {
			const { referral } = this.props;
			const jobTitle = get(referral, 'job.title');
			const newTitle = jobTitle;
			this.setState({
				translatedTitle: newTitle,
			});
			if (this.props.allMultiLingualData.length > 0) {
				this.props.setMultiLingualData(this.props.allMultiLingualData);
				const languageCode =
					this.props.currentUser.languageCode === null
						? 'US'
						: this.props.currentUser.languageCode;
				const filteredData = this.props.allMultiLingualData.filter(
					(item) => item.languageCode === languageCode
				);
				const bonusDetail = filteredData.find(
					(item) => item.key === referralCardKeys.BonusDetails
				);
				const job = filteredData.find(
					(item) => item.key === referralCardKeys.Job
				);
				const referredCandidate = filteredData.find(
					(item) => item.key === referralCardKeys.ReferredCandidate
				);
				const totalBonus = filteredData.find(
					(item) => item.key === referralCardKeys.TotalBonus
				);
				const hiredDate = filteredData.find(
					(item) => item.key === referralCardKeys.HiredDate
				);
				const startDate = filteredData.find(
					(item) => item.key === referralCardKeys.StartDate
				);
				const bonusPayment = filteredData.find(
					(item) => item.key === referralCardKeys.BonusPayments
				);
				const bonusNote = filteredData.find(
					(item) => item.key === referralCardKeys.BonusNotes
				);
				const close = filteredData.find(
					(item) => item.key === referralCardKeys.Close
				);
				const viewBonusDetail = filteredData.find(
					(item) => item.key === referralCardKeys.ViewBonusDetails
				);
				this.setState({
					ml_BonusDetails: get(bonusDetail, 'text', 'Bonus Details'),
					ml_Job: get(job, 'text', 'Job'),
					ml_ReferredCandidate: get(
						referredCandidate,
						'text',
						'Referred Candidate'
					),
					ml_TotalBonus: get(totalBonus, 'text', 'Total Bonus'),
					ml_HiredDate: get(hiredDate, 'text', 'Hired Date'),
					ml_StartDate: get(startDate, 'text', 'Start Date'),
					ml_BonusPayments: get(bonusPayment, 'text', 'Bonus Payments'),
					ml_BonusNotes: get(bonusNote, 'text', 'Bonus Notes'),
					ml_Close: get(close, 'text', 'Close'),
					ml_ViewBonusDetails: get(
						viewBonusDetail,
						'text',
						'View Bonus Details'
					),
				});
			}
		}
	}

	handleCancel = () => {
		this.setState({
			visibleBonusDetails: false,
		});
	};

	handleHired = () => {
		this.setState({ status: 'hired', bonusStatus: 'pending' });
	};

	handleReferralCancel = () => {
		this.setState({
			visibleReferralModal: false,
		});
	};

	handleVisibleChange = (flag) => {
		this.setState({ dropdownVisibility: flag });
	};

	handleUpdateStatus = (status) => {
		const { referral, onUpdateReferral } = this.props;
		if (status === 'hired') {
			this.setState({ hiredModalVisible: true });
		} else {
			this.setState({ status, bonusStatus: 'ineligible' });
			let input = {};
			const customStatuses = [
				'stage1',
				'stage2',
				'stage3',
				'stage4',
				'stage5',
				'stage6',
			];
			if (customStatuses.includes(status)) {
				const customStages = get(this.props.currentUser, 'company.stages');
				if (customStages) {
					const stages = JSON.parse(customStages);
					const stageObject = stages.find((value) => value[status]);
					const customStage = stageObject[status];
					input = {
						id: get(referral, 'id'),
						contactId: get(referral, 'contactId'),
						userId: get(referral, 'user.id'),
						jobId: get(referral, 'jobId'),
						status: 'interviewing',
						bonusStatus: 'ineligible',
						customStatus: customStage,
					};
				}
			} else {
				input = {
					id: get(referral, 'id'),
					contactId: get(referral, 'contactId'),
					userId: get(referral, 'user.id'),
					jobId: get(referral, 'jobId'),
					status,
					bonusStatus: 'ineligible',
				};
				if (status === 'interviewing') {
					input.customStatus = get(
						this.props.currentUser,
						'company.referralStatus',
						'Interviewing'
					);
				} else {
					// Explicitly set customStatus null or else the
					// referral retains the old customStatus
					input.customStatus = null;
				}
			}

			onUpdateReferral({ input });
		}
	};

	hideHiredModal = () => {
		this.setState({ hiredModalVisible: false });
	};

	resendReferral = (referral) => {
		return new Promise((resolve, reject) => {
			try {
				const { id, name, brandColor, logo, whiteLabel } = get(
					this.props.currentUser,
					'company'
				);
				const company = {
					id,
					name,
					brandColor,
					logo,
					whiteLabel,
				};
				let endpoint = '';
				const host = window.location.hostname;
				if (referral.referralType === 'email') {
					switch (host) {
						case 'localhost':
						case 'erinapp-alpha.netlify.app':
						case 'erinapp-dev.netlify.app':
						case 'testing.erinapp.com':
						case 'qa.referafriend.seaworldentertainment.com': {
							endpoint = 'base-white-resend-referral-dev-app';

							break;
						}

						case 'referralstest.aus.com': {
							endpoint = 'allied-dev-resend-referral';

							break;
						}

						case 'referrals.aus.com': {
							endpoint = 'allied-resend-referral';

							break;
						}

						default: {
							endpoint = 'base-white-resend-referral-prod-app';
						}
					}
				} else if (referral.referralType === 'text') {
					switch (host) {
						case 'localhost':
						case 'erinapp-alpha.netlify.app':
						case 'erinapp-dev.netlify.app':
						case 'testing.erinapp.com':
						case 'qa.referafriend.seaworldentertainment.com': {
							endpoint = 'dev-resend-referral';

							break;
						}

						case 'referralstest.aus.com': {
							endpoint = 'allied-dev-resend-referral';

							break;
						}

						case 'referrals.aus.com': {
							endpoint = 'allied-resend-referral';

							break;
						}

						default: {
							endpoint = 'resend-referral';
						}
					}
				}

				lambda({
					endpoint,
					variables: {
						referral,
						company,
					},
				});
				resolve();
			} catch (error) {
				console.error(error);
				reject(error);
			}
		});
	};

	showDeleteConfirm(referral) {
		const { deleteReferral } = this.props;
		Modal.confirm({
			title: 'Are you sure you want to delete this Referral?',
			content:
				'Deleting this referral will also delete any associated bonuses.',
			icon: <QuestionCircleOutlined />,
			okText: 'Confirm Delete',
			okType: 'primary',
			okButtonProps: { danger: true },
			width: 500,
			autoFocusButton: null,
			cancelText: 'Cancel',
			onOk() {
				deleteReferral(referral);
			},
		});
	}

	showModal = () => {
		this.setState({
			visibleBonusDetails: true,
		});
	};

	render() {
		const handleResendReferral = (referral) => {
			this.resendReferral(referral)
				.then(() => {
					this.setState({ resendSuccess: true });
					setTimeout(() => {
						this.setState({ resendSuccess: false });
					}, 2000);
				})
				.catch((error) => console.error(error));
		};

		const {
			referral,
			referralBonuses,
			currentCurrencyRate,
			currentCurrencySymbol,
			currentUser,
			ownershipSettings,
		} = this.props;
		const contactIncentiveBonus = get(
			this.props,
			'employee.company.contactIncentiveBonus'
		);
		const {
			ml_BonusDetails,
			ml_Job,
			ml_ReferredCandidate,
			ml_TotalBonus,
			ml_HiredDate,
			ml_StartDate,
			ml_BonusPayments,
			ml_BonusNotes,
			ml_Close,
			ml_ViewBonusDetails,
			translatedTitle,
		} = this.state;

		const referralType = get(this.props, 'referral.referralType');
		const currentTieredBonus = get(this.props, 'referral.job.tieredBonus');
		const referralDetail =
			referral.contact && get(referral, 'contact.emailAddress', '') !== null
				? get(referral, 'contact.emailAddress')
				: referral.contact && get(referral, 'contact.phoneNumber', '') !== null
					? get(referral, 'contact.phoneNumber')
					: '';

		const languageCode = get(this.props, 'currentUser.languageCode', 'US');
		const status = get(referral, 'status');
		const bonusStatus = get(referral, 'bonusStatus');
		const bonusArray = get(this.props, ['referral', 'bonuses']);
		const hireDate = formatDate(
			get(referral, 'hireDate'),
			languageCode,
			this.props.currentUser.dateFormat
		);
		const referralDate = formatDate(
			get(referral, 'referralDate'),
			languageCode,
			this.props.currentUser.dateFormat
		);
		const user = get(referral, 'user');
		const referralBonus = get(referral, 'job.referralBonus');
		const incentiveEligible = get(referral, 'incentiveEligible');
		const department = get(referral, 'job.department.name');
		const location =
			typeof get(referral, 'job.location') === 'string'
				? JSON.parse(get(referral, 'job.location'))
				: get(referral, 'job.location');
		const locations =
			typeof get(referral, 'job.locations') === 'string'
				? JSON.parse(get(referral, 'job.locations'))
				: get(referral, 'job.locations');

		let referralBonusDetails = referralBonus;
		if (typeof referralBonusDetails === 'string') {
			referralBonusDetails = JSON.parse(referralBonusDetails);
		}

		const city = get(location, 'city');
		const state = get(location, 'state');
		const amount = referralBonusDetails ? referralBonusDetails.amount : 0;

		const confirmMessage =
			status === 'hired'
				? 'Confirm Updates? Associated bonuses will be deleted.'
				: 'Confirm Updates?';
		let userGroupCurrency = get(this.props.currentUser, 'userGroup.currency');
		if (userGroupCurrency === null) userGroupCurrency = 'USD';
		const symbol = getSymbolFromCurrency(userGroupCurrency);
		let isManagerPermissionDisabled = get(
			this.props.currentUser,
			'company.disableManagerPermissions',
			false
		);
		// Preserves backwards compatibility and future compatibility with disableManagerPermissions
		const managerReferrals = this.props.currentUser.company
			?.disableManagerPermissionsByType
			? JSON.parse(
					this.props.currentUser.company.disableManagerPermissionsByType
				).managerReferrals
			: isManagerPermissionDisabled
				? 'readOnly'
				: 'edit';
		isManagerPermissionDisabled = managerReferrals !== 'edit';

		const displayAs = get(this.props.currentUser, 'displayAs');
		const isStatusDropdownDisabled = Boolean(
			isManagerPermissionDisabled && displayAs === USER_ROLES.MANAGER
		);

		const adminNote = get(referral, 'adminNote');
		let REFERRAL_STATUS = {
			referred: 'Referred',
			accepted: 'Accepted',
			interviewing: 'Interviewing',
		};
		const customStages = get(this.props.currentUser, 'company.stages');
		if (customStages) {
			const stages = JSON.parse(customStages);
			if (stages.length > 0) {
				REFERRAL_STATUS = Object.assign(REFERRAL_STATUS, ...stages);
			}
		}

		const remainingStatuses = {
			hired: 'Hired',
			ineligible: 'Ineligible',
			notHired: 'Not Hired',
			declined: 'Declined',
			transferred: 'Transferred',
			inactive: 'Inactive',
			noresponse: 'No Response',
		};
		REFERRAL_STATUS = { ...REFERRAL_STATUS, ...remainingStatuses };
		if (ownershipSettings) {
			ownershipSettings.expiryDate = formatDate(
				get(referral, 'ownershipEndDate'),
				languageCode
			);
		}

		return (
			<>
				<Card className="rd-card">
					<div className="rd-card-top">
						<div className="rdc-left">
							{translatedTitle && (
								<h4 className="rdc-name">
									<Paragraph
										ellipsis={{
											rows: 2,
											expandable: false,
											tooltip: translatedTitle,
										}}
										style={{ marginBottom: 0, color: '#0686d0' }}
									>
										<Link to={`/jobs/${get(referral, 'jobId', '')}`}>
											{translatedTitle}
										</Link>
									</Paragraph>
								</h4>
							)}
							<ul>
								{department && (
									<li>
										<i className="icon-folder" />
										<span>
											<Paragraph
												ellipsis={{
													rows: 1,
													tooltip: department,
												}}
												style={{
													marginBottom: 0,
													maxWidth: '100%',
													color: 'var(--oslo-gray)',
												}}
											>
												{department}
											</Paragraph>
										</span>
									</li>
								)}
								<li>
									<JobLocation
										allMultiLingualData={this.props.allMultiLingualData}
										currentUser={currentUser}
										location={location}
										locations={locations}
									/>
								</li>
							</ul>
							{!isStatusDropdownDisabled && (
								<div className="rdc-social-icons">
									{get(referral, 'status') === 'referred' ? (
										<Popconfirm
											title="Resend referral？"
											placement="left"
											okText="Yes"
											cancelText="No"
											icon={
												<i
													className="icon-envelope-outline"
													style={{ fontSize: 16 }}
												/>
											}
											onConfirm={() => handleResendReferral(referral)}
										>
											<Tooltip
												arrowPointAtCenter
												placement="topRight"
												title={`Resend referral to ${referralDetail}`}
												style={{ fontWeight: 1000 }}
											>
												<RetweetOutlined
													style={{
														color: COLORS.blue,
														fontSize: 18,
														fontWeight: 1000,
													}}
												/>
												{referralType === 'email' ? (
													<i
														className="icon-envelope-outline"
														style={{ fontSize: 18 }}
													/>
												) : (
													<i
														className="icon-telephone-outline"
														style={{ fontSize: 18 }}
													/>
												)}
											</Tooltip>
										</Popconfirm>
									) : null}
								</div>
							)}
							{referralBonus &&
							!currentTieredBonus &&
							get(referral, 'status') === 'hired' ? (
								<p className="rd-card-bonus">
									{get(referral, 'referralType') === 'self' ? (
										<span className="text-orange" />
									) : (
										<>
											{`${symbol}${calculateReferralBonus(
												contactIncentiveBonus,
												amount,
												incentiveEligible,
												currentTieredBonus,
												'employee',
												get(referral, 'user.userGroupId')
											)}`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')}
										</>
									)}
								</p>
							) : null}
						</div>
						<div className="rdc-right">
							<span className="rdc-referred">
								{get(referral, 'referralType') === 'self' ? (
									<span className="rdc-referred-self">Self Referred</span>
								) : (
									<>
										Referred By
										<Link to={`/employees/${get(user, 'id', '')}`}>
											{get(referral, 'user.firstName')}{' '}
											{get(referral, 'user.lastName')}
										</Link>
									</>
								)}
							</span>
							<span className="rdc-referred-date">
								<OwnershipPeriodTooltip ownershipSettings={ownershipSettings} />
								<Tooltip
									placement="top"
									title={`Referred on ${referralDate} by ${get(
										referral,
										'referralType'
									)}`}
								>
									{get(referral, 'referralType') === 'email' && (
										<i
											className="icon-envelope-outline"
											style={{ fontSize: 16 }}
										/>
									)}
									{get(referral, 'referralType') === 'text' && (
										<i
											className="icon-message-outline"
											style={{ fontSize: 16 }}
										/>
									)}
									{get(referral, 'referralType') === '' && (
										<i
											className="icon-telephone-outline"
											style={{ fontSize: 16 }}
										/>
									)}

									<span>{referralDate}</span>
								</Tooltip>
							</span>

							{hireDate !== null && status === 'hired' ? (
								<span className="rdc-referred-status">
									<span> Hired on </span> {hireDate}
								</span>
							) : null}
							<div className="rdc-action-btn">
								{!isStatusDropdownDisabled && (
									<>
										<Button
											type="link"
											className="btn-link"
											onClick={() =>
												this.setState({ visibleReferralModal: true })
											}
										>
											<i className="icon-edit" />
											<span>Edit</span>
										</Button>
										<Button
											type="link"
											className="btn-link danger"
											onClick={() => this.showDeleteConfirm(referral)}
										>
											<i className="icon-bin" />
											<span>Delete</span>
										</Button>
									</>
								)}
								{adminNote ? (
									<span>
										<Tooltip
											placement="top"
											title={adminNote}
											style={{ fontWeight: 1000 }}
										>
											<img alt="erin-note" src={note} style={{ width: 20 }} />
										</Tooltip>
									</span>
								) : null}
							</div>
						</div>
					</div>
					<div className="rdc-spacer" />
					<div className="rdc-status-dropdown">
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<span className="status-dropdown-label">Status:</span>
							<Dropdown
								key={referral.id}
								trigger={['click']}
								disabled={isStatusDropdownDisabled}
								open={this.state.dropdownVisibility}
								className="status-dropdown-btn"
								menu={{
									items: Object.keys(REFERRAL_STATUS).map((key) => ({
										key,
										label:
											key === 'hired' ? (
												<span onClick={() => this.handleUpdateStatus(key)}>
													{mapReferralStatus(key, currentUser.company)}
												</span>
											) : (
												<Popconfirm
													title={confirmMessage}
													placement="top"
													okText={ml(
														'Yes',
														currentUser,
														this.props.allMultiLingualData
													)}
													cancelText={ml(
														'No',
														currentUser,
														this.props.allMultiLingualData
													)}
													onConfirm={() => {
														this.handleVisibleChange(false);
														this.handleUpdateStatus(key);
													}}
												>
													{mapReferralStatus(key, currentUser.company)}
												</Popconfirm>
											),
										style: conditionalStatusStyle(key),
									})),
								}}
								onOpenChange={(flag) => this.handleVisibleChange(flag)}
							>
								<Button onClick={(e) => e.stopPropagation()}>
									<span style={conditionalStatusStyle(referral.status)}>
										{mapReferralStatus(referral, currentUser?.company)}
									</span>
									<DownOutlined />
								</Button>
							</Dropdown>
						</div>
						{status === 'hired' && bonusStatus && bonusArray.length > 0 ? (
							<Button type="primary" onClick={() => this.showModal()}>
								{ml_ViewBonusDetails}
							</Button>
						) : (
							<></>
						)}
					</div>

					<div className="my-progressbar-wrap">
						<ProgressIndicator
							type="referral"
							points={5}
							status={status}
							currentUser={this.props.currentUser}
							referral={referral}
							onClick={this.handleUpdateStatus}
						/>
					</div>
					<ReferralHiredModal
						referral={referral}
						referralBonuses={referralBonuses}
						updateBonusData={this.updateBonusData}
						job={this.props.referral}
						visible={this.state.hiredModalVisible}
						handleCancel={this.hideHiredModal}
						handleHired={this.handleHired}
						currentUser={this.props.currentUser}
						onUpdateJob={this.props.onUpdateJob}
						onUpdateReferral={this.props.onUpdateReferral}
					/>
				</Card>

				<Modal
					title="Bonus Details"
					open={this.state.visibleBonusDetails}
					footer={null}
					onCancel={this.handleCancel}
				>
					<MyReferralsBonusDetailsModal
						currentTieredBonus={currentTieredBonus}
						visible={this.state.visibleBonusDetails}
						status={status}
						userGroup={get(referral, 'user.userGroupId')}
						currentCurrencyRate={currentCurrencyRate}
						currentCurrencySymbol={currentCurrencySymbol}
						data={referral}
						handleCancel={this.handleCancel}
						bonusDetailsText={ml_BonusDetails}
						jobText={ml_Job}
						referredCandidateText={ml_ReferredCandidate}
						totalBonusText={ml_TotalBonus}
						hiredDateText={ml_HiredDate}
						startDateText={ml_StartDate}
						bonusPaymentsText={ml_BonusPayments}
						bonusNotesText={ml_BonusNotes}
						closeText={ml_Close}
						currentUser={this.props.currentUser}
						translatedTitle={translatedTitle}
						onCancel={this.handleCancel}
					/>
				</Modal>
				<Modal
					title="Referral Details"
					open={this.state.visibleReferralModal}
					footer={null}
					onCancel={this.handleReferralCancel}
				>
					<ReferralEditModal
						handleReferralCancel={this.handleReferralCancel}
						referral={referral}
						allMultiLingualData={this.props.allMultiLingualData}
						onUpdateReferral={this.props.onUpdateReferral}
					/>
				</Modal>
			</>
		);
	}
}

export default ReferralCardJobComponent;
