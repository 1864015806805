import { DownOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
	Button,
	DatePicker,
	Dropdown,
	Input,
	Menu,
	Modal,
	message,
} from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { queryReferralEligibilityResults } from 'src/_shared/api/graphql/custom/referrals/';
import { BONUS_STATUS, USER_ROLES } from '../constants';
import { formatDate, mapReferralStatus, ml } from '../services/utils';

function BonusDetailsModal(props) {
	const {
		onClose,
		bonus,
		client,
		onUpdateBonus,
		requireBonusChangeReason,
		updateReferralBonusStatus,
		currentUser,
		allMultiLingualData,
		awaitingResponse,
	} = props;
	const languageCode = get(currentUser, 'languageCode', 'US');
	const dateFormat = get(currentUser, 'dateFormat');
	const displayAs = get(currentUser, 'displayAs');
	const managerBonuses = currentUser.company?.disableManagerPermissionsByType
		? JSON.parse(currentUser.company.disableManagerPermissionsByType)
				.managerBonuses
		: 'hidden';

	const isManagerPermissionDisabled = managerBonuses !== 'edit';

	const userGroupCurrency = get(bonus, 'user.userGroup.currency', 'USD');
	const symbol = getSymbolFromCurrency(userGroupCurrency);

	const referrer = {
		firstName: get(bonus, 'user.firstName'),
		lastName: get(bonus, 'user.lastName'),
		URL: `/employees/${bonus.userId}`,
	};

	const referredCandidate = {
		firstName: get(bonus, 'contact.firstName'),
		lastName: get(bonus, 'contact.lastName'),
		URL: `/referrals/${bonus.referralId}`,
	};

	const bonusRecipient =
		bonus.recipientType === 'employee'
			? {
					firstName: get(bonus, 'user.firstName'),
					lastName: get(bonus, 'user.lastName'),
					URL: `/employees/${bonus.userId}`,
				}
			: {
					firstName: get(bonus, 'contact.firstName'),
					lastName: get(bonus, 'contact.lastName'),
					URL: `/referrals/${bonus.referralId}`,
				};

	const [isBonusEdited, setIsBonusEdited] = useState(false);
	const [reasonForChange, setReasonForChange] = useState('');
	const [editMode, setEditMode] = useState(false);
	const [notes, setNotes] = useState(bonus.notes || '');
	const [recipientType] = useState(bonus.recipientType || null);
	const [bonusStatus, setBonusStatus] = useState(bonus.bonusStatus || null);
	const [amountDue, setAmountDue] = useState(
		(bonus.amountDue && Number(bonus.amountDue)) || 0
	);
	const [startDate, setStartDate] = useState(
		(bonus.startDate && dayjs(bonus.startDate)) || null
	);
	const [earnedDate, setEarnedDate] = useState(
		(bonus.earnedDate && dayjs(bonus.earnedDate)) || null
	);
	const [paidDate, setPaidDate] = useState(
		(bonus.paidDate && dayjs(bonus.paidDate)) || null
	);
	const [translatedJobTitle, setTranslatedJobTitle] = useState('');

	// Translate job title on initial render and when bonus.job.title, and current user changes
	useEffect(() => {
		setTranslatedJobTitle(get(bonus, 'job.title'));
	}, [get(bonus, 'job.title'), currentUser]);

	const [eligibilityResults, setEligibilityResults] = useState([]);

	useEffect(() => {
		const fetchEligibilityResults = async () => {
			if (bonus.referralId) {
				try {
					const { data } = await client.query({
						query: gql(queryReferralEligibilityResults),
						variables: {
							id: get(bonus, 'referralId'),
						},
						fetchPolicy: 'network-only',
					});
					const result = JSON.parse(
						data.getReferral.eligibilityResults ?? '[]'
					);
					setEligibilityResults(result);
				} catch (error) {
					console.error('Failed to fetch eligibility results:', error);
				}
			}
		};

		fetchEligibilityResults();
	}, [bonus.referralId, awaitingResponse]);

	const submitChanges = () => {
		const logEntries = generateLogs();
		if (logEntries.length === 0) {
			setEditMode(false);
			setReasonForChange('');
			return;
		}

		if (!reasonForChange && bonus.referralId) {
			message.error(
				ml('Reason for change required', currentUser, allMultiLingualData)
			);
			return;
		}

		const updatedBonus = {
			id: bonus.id,
			earnedDate: earnedDate && earnedDate.toISOString(),
			startDate: startDate && startDate.toISOString(),
			paidDate: paidDate && paidDate.toISOString(),
			recipientType,
			bonusStatus,
			amountDue,
			notes,
		};

		props.updateBonusInState(updatedBonus);
		onUpdateBonus(updatedBonus);

		if (isBonusEdited) {
			updateReferralBonusStatus(bonus.referralId, logEntries);
		}

		setEditMode(false);
		onClose();
	};

	const createLogMessage = (change) => {
		let changeLog = `${currentUser.emailAddress} updated the ${change} on payment "${bonus.payment}"`;
		if (reasonForChange) changeLog += ` with the reason "${reasonForChange}"`;
		return changeLog;
	};

	const generateLogs = () => {
		const currentDate = dayjs().toISOString();
		const logArray = [];
		const normalFormat = (value) =>
			value == 'earned' ? `"eligible"` : `"${value}"`;
		const dateFormat = (value) =>
			`"${value ? formatDate(value) : 'undefined'}"`;
		const fieldsToCheck = [
			{
				key: 'amountDue',
				newValue: amountDue,
				label: 'bonus amount',
				formatter: normalFormat,
			},
			{
				key: 'startDate',
				newValue: startDate && startDate.toISOString(),
				label: 'start date',
				formatter: dateFormat,
			},
			{
				key: 'earnedDate',
				newValue: earnedDate && earnedDate.toISOString(),
				label: 'eligible date',
				formatter: dateFormat,
			},
			{
				key: 'paidDate',
				newValue: paidDate && paidDate.toISOString(),
				label: 'paid date',
				formatter: dateFormat,
			},
			{
				key: 'bonusStatus',
				newValue: bonusStatus,
				label: 'status',
				formatter: normalFormat,
			},
			{
				key: 'notes',
				newValue: notes ? notes : null,
				label: 'bonus notes',
				formatter: normalFormat,
			},
		];

		for (const { key, newValue, label, formatter } of fieldsToCheck) {
			// Necessary because notes can be empty string or null and we need it null for comparison
			const oldValue = key == 'notes' && bonus[key] == '' ? null : bonus[key];

			if (newValue != oldValue) {
				logArray.push({
					date: currentDate,
					changeLog: createLogMessage(`${label} to ${formatter(newValue)}`),
				});
			}
		}

		return logArray;
	};

	const renderLogs = () => {
		if (!bonus.referralId || eligibilityResults.length === 0) {
			return null;
		}

		return (
			<li>
				<label>{ml('Logs', currentUser, allMultiLingualData)}:</label>
				<div
					className="log-box"
					style={{
						maxHeight: '100px',
						display: 'block',
						width: '100%',
					}}
				>
					<div id="log">
						{eligibilityResults.map((entry, index) => (
							<p key={index}>
								<strong>
									{formatDate(get(entry, 'date')) +
										(get(entry, 'results')
											? ' Eligibility Check - '
											: ' Bonus Record Change - ')}
								</strong>
								{get(entry, 'results') ?? get(entry, 'changeLog')}
							</p>
						))}
					</div>
				</div>
			</li>
		);
	};

	const history = useHistory();

	const navigateTo = (URL) => {
		history.push(URL);
	};

	return (
		<Modal
			centered
			open
			footer={null}
			title={ml('Bonus Details', currentUser, allMultiLingualData)}
			closable={false}
		>
			<div className="text-right">
				{isManagerPermissionDisabled &&
				displayAs === USER_ROLES.MANAGER ? null : (
					<Button
						type="link"
						onClick={() => {
							setEditMode(true);
							setIsBonusEdited(true);
						}}
					>
						{}
						<i className="icon-edit" />
						{ml('Edit Bonus', currentUser, allMultiLingualData)}
					</Button>
				)}
			</div>
			<ul className="label-data">
				<li>
					<label>
						{ml('Recipient Name', currentUser, allMultiLingualData)}:
					</label>
					<a
						href={bonusRecipient.URL}
						onClick={(event) => {
							event.preventDefault();
							navigateTo(bonusRecipient.URL);
						}}
					>
						{bonusRecipient.firstName}&nbsp;{bonusRecipient.lastName}
					</a>
				</li>
				<li>
					<label>
						{ml('Recipient Type', currentUser, allMultiLingualData)}:
					</label>
					{bonus.recipientType &&
						bonus.recipientType.charAt(0).toUpperCase() +
							bonus.recipientType.slice(1)}
				</li>
				<li>
					<label>{ml('Job', currentUser, allMultiLingualData)}:&nbsp;</label>
					<a
						href={`/jobs/${get(bonus, 'job.id')}`}
						onClick={(event) => {
							event.preventDefault();
							navigateTo(`/jobs/${get(bonus, 'job.id')}`);
						}}
					>
						{translatedJobTitle}
					</a>
				</li>
				{recipientType === 'candidate' ? (
					<li>
						<label>
							{`${ml(
								`${mapReferralStatus('referred', currentUser.company)} By`,
								currentUser,
								allMultiLingualData
							)}: `}
						</label>
						<a
							href={referrer.URL}
							onClick={(event) => {
								event.preventDefault();
								navigateTo(referrer.URL);
							}}
						>
							{referrer.firstName}&nbsp;{referrer.lastName}
						</a>
					</li>
				) : (
					<li>
						<label>
							{ml('Referred Candidate', currentUser, allMultiLingualData)}:
						</label>
						<a
							href={referredCandidate.URL}
							onClick={(event) => {
								event.preventDefault();
								navigateTo(referredCandidate.URL);
							}}
						>
							{referredCandidate?.firstName}&nbsp;{referredCandidate?.lastName}
						</a>
					</li>
				)}
				<li>
					<label>
						{ml('Bonus Amount', currentUser, allMultiLingualData)}:&nbsp;
					</label>
					{editMode ? (
						<div className="custom-form-group">
							<Input
								value={amountDue}
								type="number"
								className="custom-input"
								onChange={(e) => {
									setAmountDue(e.target.value);
								}}
							/>
						</div>
					) : (
						<span className="text-green">
							{symbol}
							{bonus.amountDue
								.toString()
								.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',') ||
								(0).toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')}
						</span>
					)}
				</li>
				<li>
					<label>{ml('Currency', currentUser, allMultiLingualData)}:</label>
					{symbol}&nbsp;{userGroupCurrency}
				</li>
				<li>
					<label>
						{ml('Referring Employee Title', currentUser, allMultiLingualData)}:
					</label>
					{get(bonus, 'user.title')}
				</li>
				{get(bonus, 'user.accountClaim.companyNumber') && (
					<li>
						<label>Referring Employee Company No.:</label>
						{bonus?.user?.accountClaim?.companyNumber}
					</li>
				)}
				<li>
					<label>
						{ml('Referring Employee Status', currentUser, allMultiLingualData)}:
					</label>
					{get(bonus.user, 'active') ? 'Active' : 'Inactive'}
				</li>
				{get(bonus, 'user.accountClaim') &&
				get(bonus, 'user.accountClaim.employeeId') ? (
					<li>
						<label>Referring Employee Number:</label>
						{get(bonus, 'user.accountClaim.employeeId')}
					</li>
				) : null}

				{get(bonus, 'contact.accountClaim') &&
				get(bonus, 'contact.accountClaim.employeeId') ? (
					<li>
						<label>Referred Employee Number:</label>
						{get(bonus, 'contact.accountClaim.employeeId')}
					</li>
				) : null}

				<li>
					<label>
						{`${ml(
							`${mapReferralStatus('hired', currentUser.company)} Date`,
							currentUser,
							allMultiLingualData
						)}:`}
					</label>
					{formatDate(bonus.hireDate, languageCode, dateFormat)}
				</li>
				<li>
					<label>{`${ml(
						'Start Date',
						currentUser,
						allMultiLingualData
					)}:`}</label>
					{editMode ? (
						<div className="custom-form-group">
							<DatePicker
								format="MM-DD-YYYY"
								value={startDate}
								className="custom-input"
								onChange={(value) => setStartDate(value)}
							/>
						</div>
					) : (
						<>{formatDate(bonus.startDate, languageCode, dateFormat)}</>
					)}
				</li>
				<li>
					<label>{`${ml(
						'Eligible Date',
						currentUser,
						allMultiLingualData
					)}:`}</label>
					{editMode ? (
						<div className="custom-form-group">
							<DatePicker
								format="MM-DD-YYYY"
								value={earnedDate}
								className="custom-input"
								onChange={(value) => {
									setEarnedDate(value);
								}}
							/>
						</div>
					) : (
						<>{formatDate(bonus.earnedDate, languageCode, dateFormat)}</>
					)}
				</li>
				<li>
					<label>
						{(paidDate || editMode) &&
							`${ml('Paid Date', currentUser, allMultiLingualData)}:`}
					</label>
					{editMode ? (
						<div className="custom-form-group">
							<DatePicker
								format="MM-DD-YYYY"
								value={paidDate}
								className="custom-input"
								onChange={(value) => setPaidDate(value)}
							/>
						</div>
					) : paidDate ? (
						<>{formatDate(bonus?.paidDate, languageCode, dateFormat)}</>
					) : null}
				</li>
				<li>
					<label>{ml('Status', currentUser, allMultiLingualData)}:</label>
					{editMode ? (
						<Dropdown
							trigger={['click']}
							overlay={
								<Menu>
									{Object.keys(BONUS_STATUS).map((key) => {
										return (
											<Menu.Item key={key} onClick={() => setBonusStatus(key)}>
												{ml(
													BONUS_STATUS[key],
													currentUser,
													allMultiLingualData
												)}
											</Menu.Item>
										);
									})}
								</Menu>
							}
						>
							<Button>
								{ml(
									BONUS_STATUS[bonusStatus],
									currentUser,
									allMultiLingualData
								)}
								<DownOutlined />
							</Button>
						</Dropdown>
					) : (
						<>
							{/* Convert earned to Eligible on front end, then capitalize any other status if exists */}
							{bonus?.bonusStatus === 'earned'
								? ml('Eligible', currentUser, allMultiLingualData)
								: bonus?.bonusStatus
									? ml(bonus.bonusStatus, currentUser, allMultiLingualData)
											.charAt(0)
											.toUpperCase() +
										ml(
											bonus.bonusStatus,
											currentUser,
											allMultiLingualData
										).slice(1)
									: null}
						</>
					)}
				</li>
				<li>
					<label>{ml('Bonus Notes', currentUser, allMultiLingualData)}:</label>
					{editMode ? (
						<Input.TextArea
							value={notes}
							rows={4}
							className="custom-input"
							style={{ width: '100%' }}
							onChange={(e) => {
								setNotes(e.target.value);
							}}
						/>
					) : (
						<>
							{bonus.notes}

							{isManagerPermissionDisabled &&
							displayAs === USER_ROLES.MANAGER ? null : (
								<div style={{ width: '100%', marginTop: '8px' }}>
									<Button
										type="link"
										onClick={() => {
											setEditMode(true);
											setIsBonusEdited(true);
										}}
									>
										<PlusCircleOutlined size={20} />
										{ml('Add Note', currentUser, allMultiLingualData)}
									</Button>
								</div>
							)}
						</>
					)}
				</li>
				{!editMode && renderLogs()}
				{editMode && bonus.referralId ? (
					<li>
						<label>
							<span style={{ color: 'red' }}>* </span>
							{ml('Reason for Change', currentUser, allMultiLingualData)}:
						</label>
						<Input
							value={reasonForChange}
							className="custom-input"
							placeholder={
								ml(
									'Provide a reason for change',
									currentUser,
									allMultiLingualData
								) +
								' ' +
								'(' +
								ml('required', currentUser, allMultiLingualData) +
								')'
							}
							style={{ width: '100%' }}
							onChange={(e) => {
								setReasonForChange(e.target.value);
							}}
						/>
					</li>
				) : null}
			</ul>

			{editMode ? (
				<div className="modal-footer-btn">
					<Button
						className="btn-min-width"
						onClick={() => {
							setEditMode(false);
							setIsBonusEdited(false);
						}}
					>
						{ml('Cancel', currentUser, allMultiLingualData)}
					</Button>
					<Button
						type="primary"
						className="btn-min-width"
						onClick={() => submitChanges()}
					>
						{ml('Save', currentUser, allMultiLingualData)}
					</Button>
				</div>
			) : (
				<div className="modal-footer-btn">
					<Button
						type="primary"
						className="btn-min-width"
						onClick={() => onClose()}
					>
						{ml('Close', currentUser, allMultiLingualData)}
					</Button>
				</div>
			)}
		</Modal>
	);
}

export default withApollo(BonusDetailsModal);
