import { DownOutlined } from '@ant-design/icons';
import {
	Button,
	Dropdown,
	Input,
	Menu,
	Popconfirm,
	Table,
	message,
} from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import { queryBonusByReferralIdIndex } from 'src/_shared/api/graphql/custom/bonuses/';
import { queryReferralEligibilityResults } from 'src/_shared/api/graphql/custom/referrals/';
import BonusDetailsModal from 'src/_shared/components/BonusTableDetailsModalComponent.jsx';
import JobTitleComponent from 'src/_shared/components/JobTitleComponent.jsx';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import {
	formatDate,
	initialCaps,
	mapReferralStatus,
	ml,
} from 'src/_shared/services/utils.js';
import { BONUS_STATUS, USER_ROLES } from '../constants';
import { dropdownMenuItem, popoverStyles } from './referralsTableStyles.js';

class BonusTable extends Component {
	constructor(props) {
		super(props);
		const { currentUser, allMultiLingualData } = props;
		this.state = {
			bonuses: get(this.props, 'bonuses'),
			detailsModalBonus: null,
			visibility: {},
			reasonForChange: '',
			awaitingResponse: false,
			headers: {
				name: ml('Name', currentUser, allMultiLingualData),
				recipientType: ml('Recipient Type', currentUser, allMultiLingualData),
				job: ml('Job', currentUser, allMultiLingualData),
				referral: ml('Referral', currentUser, allMultiLingualData),
				bonusAmount: ml('Bonus Amount', currentUser, allMultiLingualData),
				elligibleDate: ml('Eligible Date', currentUser, allMultiLingualData),
				payment: ml('Payment', currentUser, allMultiLingualData),
				status: ml('Status', currentUser, allMultiLingualData),
				details: ml('Details', currentUser, allMultiLingualData),
			},
			ml_of: ml('of', currentUser, allMultiLingualData),
		};
	}

	async componentDidMount() {
		if (get(this.props, 'filteredReferralId', false)) {
			const filteredBonuses = this.state.bonuses.filter(
				(bonus) => bonus.referralId === this.props.filteredReferralId
			);
			this.setState({
				bonuses: filteredBonuses,
			});
			await this.getExportData(filteredBonuses);
		} else {
			await this.getExportData(this.props.bonuses);
		}
	}

	async componentDidUpdate(prevProps) {
		if (
			prevProps.currentUser.languageCode !== this.props.currentUser.languageCode
		) {
			const { currentUser, allMultiLingualData } = this.props;
			this.setState({
				headers: {
					name: ml('Name', currentUser, allMultiLingualData),
					recipientType: ml('Recipient Type', currentUser, allMultiLingualData),
					job: ml('Job', currentUser, allMultiLingualData),
					referral: ml('Referral', currentUser, allMultiLingualData),
					bonusAmount: ml('Bonus Amount', currentUser, allMultiLingualData),
					elligibleDate: ml('Eligible Date', currentUser, allMultiLingualData),
					payment: ml('Payment', currentUser, allMultiLingualData),
					status: ml('Status', currentUser, allMultiLingualData),
					details: ml('Details', currentUser, allMultiLingualData),
				},
				ml_of: ml('of', currentUser, allMultiLingualData),
			});
		}

		if (prevProps.filteredReferralId !== this.props.filteredReferralId) {
			const filteredBonuses = this.state.bonuses.filter(
				(bonus) => bonus.referralId === this.props.filteredReferralId
			);
			this.setState({
				bonuses: filteredBonuses,
			});
			await this.getExportData(filteredBonuses);
		}
	}

	getExportData = async (data) => {
		const { currentUser, company, setExportData, allMultiLingualData, client } =
			this.props;
		const { headers, ml_of } = this.state;
		const languageCode = currentUser.languageCode ?? 'US';

		const detailHeaders = {
			currency: ml('Currency', currentUser, allMultiLingualData),
			title: ml('Referring Employee Title', currentUser, allMultiLingualData),
			companyNumber: ml(
				'Referring Employee Company Number',
				currentUser,
				allMultiLingualData
			),
			employeeNumber: ml(
				'Referring Employee Number',
				currentUser,
				allMultiLingualData
			),
			referredNumber: ml(
				'Referred Employee Number',
				currentUser,
				allMultiLingualData
			),
			status: ml('Referring Employee Status', currentUser, allMultiLingualData),
			hiredDate: ml(
				`${mapReferralStatus('hired', currentUser.company)} Date`,
				currentUser,
				allMultiLingualData
			),
			startDate: ml('Start Date', currentUser, allMultiLingualData),
			eligibleDate: ml('Eligible Date', currentUser, allMultiLingualData),
			paidDate: ml('Paid Date', currentUser, allMultiLingualData),
			logs: ml('Logs', currentUser, allMultiLingualData),
		};
		const ml_active = ml('Active', currentUser, allMultiLingualData);
		const ml_inactive = ml('Inactive', currentUser, allMultiLingualData);

		const includeCompanyNumber = data.some(
			(record) => record.user?.accountClaim?.companyNumber
		);
		const includeEmployeeNumber = data.some(
			(record) => record.user?.accountClaim?.employeeId
		);
		const includeReferredNumber = data.some(
			(record) => record.contact?.accountClaim?.employeeId
		);

		const parseName = (record) => {
			const userType = record.recipientType === 'employee' ? 'user' : 'contact';
			return `${record[userType].firstName ?? ''} ${record[userType].lastName ?? ''}`;
		};

		const parseBonus = (record) => {
			const formattedAmount =
				record.amountDue?.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',') ??
				'';
			const selectedCurrency =
				record.currency ?? record.user?.userGroup?.currency ?? 'USD';
			return `${selectedCurrency}${formattedAmount}`;
		};

		const parseCurrency = (record) => {
			const currency = record.user?.userGroup?.currency ?? 'USD';
			const symbol = getSymbolFromCurrency(currency);
			return `${symbol} ${currency}`;
		};

		const exportPromises = data.map(async (record) => {
			const row = {
				[headers.name]: parseName(record),
				[headers.recipientType]: initialCaps(record.recipientType ?? ''),
				[headers.job]: `${record.job.title}`,
				[headers.referral]: `${record.contact?.firstName ?? ''} ${record.contact?.lastName ?? ''}`,
				[headers.bonusAmount]: parseBonus(record),
				[headers.elligibleDate]: formatDate(
					record.earnedDate,
					currentUser.languageCode,
					currentUser.dateFormat
				),
				[headers.payment]: record.payment.replace('of', ml_of),
				[headers.status]: `${ml(BONUS_STATUS[record.bonusStatus], currentUser, company)}`,
				[detailHeaders.currency]: parseCurrency(record),
				[detailHeaders.title]: `${record.user?.title ?? ''}`,
				[detailHeaders.status]: record.user.active ? ml_active : ml_inactive,
				[detailHeaders.hiredDate]: formatDate(
					record.hiredDate,
					languageCode,
					allMultiLingualData
				),
				[detailHeaders.startDate]: formatDate(
					record.startDate,
					languageCode,
					allMultiLingualData
				),
				[detailHeaders.eligibleDate]: formatDate(
					record.earnedDate,
					languageCode,
					allMultiLingualData
				),
				[detailHeaders.paidDate]: formatDate(
					record.paidDate,
					languageCode,
					allMultiLingualData
				),
			};

			if (includeCompanyNumber) {
				row[detailHeaders.companyNumber] =
					record.user?.accountClaim?.companyNumber ?? '';
			}

			if (includeEmployeeNumber) {
				row[detailHeaders.employeeNumber] =
					record.user?.accountClaim?.employeeId ?? '';
			}

			if (includeReferredNumber) {
				row[detailHeaders.referredNumber] =
					record?.contact?.accountClaim?.employeeId ?? '';
			}

			if (record.referralId) {
				try {
					const { data } = await client.query({
						query: gql(queryReferralEligibilityResults),
						variables: {
							id: record.referralId,
						},
						fetchPolicy: 'netword-only',
					});
					const result = JSON.parse(
						data.getReferral.eligibilityResults ?? '[]'
					);
					const logStrings = result.map(
						(entry) =>
							`${formatDate(entry.date)} ${entry.results ? 'Eligibility Check -' : 'Bonus Record Change -'} ${entry.results ?? entry.changeLog}`
					);
					row[detailHeaders.logs] = logStrings.join('\n');
				} catch {
					row[detailHeaders.logs] = 'Unavailable';
				}
			}

			return row;
		});

		const exportData = await Promise.all(exportPromises);
		setExportData(exportData);
	};

	getPaymentLink(payment, referralId) {
		const payString = payment === null ? '' : payment;
		const splitLink = payString.split('');
		const lastChar = splitLink.pop();
		const label = splitLink.join('').replace('of', this.state.ml_of);
		const link = (
			<div>
				{label}{' '}
				<Button
					type="link"
					className="btn-link px-1"
					style={{ height: 'auto' }}
					onClick={() => this.props.filterByReferralId(referralId)}
				>
					{lastChar}
				</Button>
			</div>
		);
		return link;
	}

	createLogMessage = (status, payment) => {
		const { currentUser } = this.props;
		const updatedStatus = status == 'earned' ? 'eligible' : status;
		let changeLog = `${currentUser.emailAddress} updated the status to "${updatedStatus}" on payment "${payment}"`;
		if (this.state.reasonForChange)
			changeLog += ` with the reason "${this.state.reasonForChange}"`;
		return changeLog;
	};

	updateBonusInState = (updatedBonus) => {
		const { bonuses } = this.state;
		const index = bonuses.findIndex((bonus) => bonus.id === updatedBonus.id);
		if (index !== -1) {
			const mergedBonus = { ...bonuses[index], ...updatedBonus };
			const updatedBonuses = [
				...bonuses.slice(0, index),
				mergedBonus,
				...bonuses.slice(index + 1),
			];
			this.setState({ bonuses: updatedBonuses });
		} else {
			console.error('Bonus not found in the state.');
		}
	};

	handleUpdateBonusStatus = (status, id, referralId, payment) => {
		const { currentUser, allMultiLingualData } = this.props;

		this.setState((prevState) => ({
			visibility: {
				...prevState.visibility,
				[id]: false,
			},
		}));

		if (!this.state.reasonForChange.trim() && referralId) {
			message.error(
				ml('Reason for change required', currentUser, allMultiLingualData)
			);
			return;
		}

		const updatedBonus = {
			id,
			bonusStatus: status,
			paidDate: status === 'paid' ? new Date().toISOString() : null,
		};

		this.updateBonusInState(updatedBonus);

		const { onUpdateBonus } = this.props;
		onUpdateBonus(updatedBonus);

		if (referralId) {
			const changeLog = this.createLogMessage(status, payment);
			const logEntry = {
				date: dayjs().toISOString(),
				changeLog,
			};
			this.setState({ awaitingResponse: true }, () => {
				this.updateReferralBonusStatus(referralId, logEntry);
			});
		}
	};

	handleReasonForChange = (e) => {
		this.setState({ reasonForChange: e.target.value });
	};

	handleTableChange = async (event) => {
		if (event.action === 'sort') {
			await this.getExportData(event.currentDataSource);
		}
	};

	handleVisibleChange = (key, flag) => {
		this.setState((prevState) => ({
			visibility: {
				...prevState.visibility,
				[key]: flag,
			},
			reasonForChange: '',
		}));
	};

	updateReferralBonusStatus = (referralId, logEntry) => {
		try {
			if (!referralId) return;
			const { onUpdateReferral } = this.props;
			this.props.client
				.query({
					query: gql(queryBonusByReferralIdIndex),
					variables: {
						referralId,
					},
				})
				.then((response) => {
					const bonuses = get(
						response,
						'data.queryBonusByReferralIdIndex.items'
					);

					return this.props.client
						.query({
							query: gql(queryReferralEligibilityResults),
							variables: {
								id: referralId,
							},
							fetchPolicy: 'network-only',
						})
						.then((response) => {
							console.log(response);
							const eligibilityResults = JSON.parse(
								response.data.getReferral.eligibilityResults ?? '[]'
							);
							return { bonuses, eligibilityResults };
						});
				})
				.then(({ bonuses, eligibilityResults }) => {
					const eligibilityResultsArray = eligibilityResults;
					if (Array.isArray(logEntry)) {
						eligibilityResultsArray.push(...logEntry);
					} else {
						eligibilityResultsArray.push(logEntry);
					}

					const newEligibilityResults = JSON.stringify(eligibilityResultsArray);
					if (bonuses) {
						const total = bonuses.length;
						let totalEarned = 0;
						let totalPaid = 0;
						let totalPending = 0;
						let totalNeedsReview = 0;
						for (const bonus of bonuses) {
							const status = get(bonus, 'bonusStatus');
							if (status === 'paid' || status === 'earned') {
								totalEarned++;
							}

							if (status === 'paid') {
								totalPaid++;
							}

							if (status === 'pending') {
								totalPending++;
							}

							if (status === 'needsReview') {
								totalNeedsReview++;
							}
						}

						let bonusStatus;

						if (total === totalPaid) {
							bonusStatus = 'paid';
						} else if (total === totalEarned) {
							bonusStatus = 'earned';
						} else if (totalNeedsReview > 0) {
							bonusStatus = 'needsReview';
						} else if (totalPending > 0) {
							bonusStatus = 'pending';
						} else {
							bonusStatus = 'ineligible';
						}

						const input = {
							input: {
								id: referralId,
								bonusStatus,
								eligibilityResults: newEligibilityResults,
							},
						};

						onUpdateReferral(input).then(() => {
							this.setState({ awaitingResponse: false });
						});
					}
				});
		} catch (error) {
			console.error(error);
		}
	};

	conditionalStatusStyle = (key) => {
		if (key === 'pending') {
			return { color: 'var(--tan-hide)', fontWeight: 600 };
		}

		if (key === 'ineligible') {
			return { color: 'var(--sunset-orange)', fontWeight: 600 };
		}

		if (key === 'earned') {
			return { color: 'var(--fern)', fontWeight: 600 };
		}

		if (key === 'paid') {
			return { color: 'var(--forest-green)', fontWeight: 600 };
		}

		if (key === 'needsReview') {
			return { color: 'var(--mellow-yellow)', fontWeight: 600 };
		}
	};

	render() {
		const { allMultiLingualData, currentUser, sortByAlph, theme, company } =
			this.props;
		const { bonuses, detailsModalBonus, headers } = this.state;

		const managerBonuses = currentUser.company?.disableManagerPermissionsByType
			? JSON.parse(currentUser.company.disableManagerPermissionsByType)
					.managerBonuses
			: 'hidden';
		const isManagerPermissionDisabled = managerBonuses !== 'edit';
		const displayAS = get(this.props.currentUser, 'displayAs');
		const isStatusDropdownDisabled = Boolean(
			isManagerPermissionDisabled && displayAS === USER_ROLES.MANAGER
		);
		const columns = [
			{
				title: headers.name,
				key: 'name',
				width: '15%',
				showSorterTooltip: false,
				sorter(a, b) {
					const aFirstName =
						get(a, 'recipientType') === 'employee'
							? get(a, 'user.firstName')
							: get(a, 'contact.firstName');
					const aLastName =
						get(a, 'recipientType') === 'employee'
							? get(a, 'user.lastName')
							: get(a, 'contact.lastName');
					const bFirstName =
						get(b, 'recipientType') === 'employee'
							? get(b, 'user.firstName')
							: get(b, 'contact.firstName');
					const bLastName =
						get(b, 'recipientType') === 'employee'
							? get(b, 'user.lastName')
							: get(b, 'contact.lastName');
					return sortByAlph(aFirstName + aLastName, bFirstName + bLastName);
				},

				render(record, data) {
					const firstName =
						get(data, 'recipientType') === 'employee'
							? get(data, 'user.firstName')
							: get(data, 'contact.firstName');
					const lastName =
						get(data, 'recipientType') === 'employee'
							? get(data, 'user.lastName')
							: get(data, 'contact.lastName');
					const URL =
						get(data, 'recipientType') === 'employee'
							? `/employees/${get(data, 'userId')}`
							: `/referrals/${get(data, 'referralId')}`;
					return (
						<Link to={URL} className="table-link">
							{firstName} {lastName}
						</Link>
					);
				},
			},
			{
				title: headers.recipientType,
				dataIndex: 'recipientType',
				key: 'recipientType',
				showSorterTooltip: false,
				sorter: (a, b) => sortByAlph(a.recientType, b.recipientType),
				render(type) {
					if (type && typeof type === 'string') {
						const value = type.split('');
						value.splice(0, 1, value[0].toUpperCase());
						return value.join('');
					}
				},
			},
			{
				title: headers.job,
				dataIndex: 'job',
				key: 'job',
				width: '16%',
				showSorterTooltip: false,
				sorter: (a, b) => sortByAlph(get(a, 'job.title'), get(b, 'job.title')),
				render: (job) =>
					job === null ? null : (
						<Link className="table-link" to={`/jobs/${job.id}`}>
							<JobTitleComponent
								jobTitle={job.title}
								currentUser={get(this.props, 'currentUser')}
							/>
						</Link>
					),
			},
			{
				title: headers.referral,
				key: 'referral',
				width: '11%',
				showSorterTooltip: false,
				sorter(a, b) {
					const aFirstName = get(a, 'contact.firstName');
					const aLastName = get(a, 'contact.lastName');
					const bFirstName = get(b, 'contact.firstName');
					const bLastName = get(b, 'contact.lastName');
					return sortByAlph(aFirstName + aLastName, bFirstName + bLastName);
				},
				render(bonus) {
					if (bonus.referral !== null && bonus.contact !== null) {
						return (
							<Link
								className="table-link"
								to={`/referrals/${bonus.referralId}`}
							>
								{bonus.contact.firstName + ' ' + bonus.contact.lastName}
							</Link>
						);
					}

					if (!bonus.referral && bonus.contact !== null) {
						return (
							<>{bonus.contact.firstName + ' ' + bonus.contact.lastName}</>
						);
					}
				},
			},
			{
				title: headers.bonusAmount,
				dataIndex: 'amountDue',
				key: 'amountDue',
				width: '10%',
				showSorterTooltip: false,
				sorter(a, b) {
					return sortByAlph(a.amountDue, b.amountDue);
				},
				render(amount, row) {
					const amountValue =
						amount > 0
							? amount.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
							: amount;
					let userGroupCurrency = get(row, 'user.userGroup.currency');
					userGroupCurrency = userGroupCurrency ? userGroupCurrency : 'USD';
					let currency = get(row, 'currency');
					currency = currency ? currency : userGroupCurrency;
					const symbol = getSymbolFromCurrency(currency);
					return (
						<span className="text-green">
							{symbol}
							{amountValue}
						</span>
					);
				},
			},
			{
				title: headers.elligibleDate,
				dataIndex: 'earnedDate',
				key: 'earnedDate',
				width: '10%',
				showSorterTooltip: false,
				sorter: (a, b) => sortByAlph(dayjs(b.earnedDate), dayjs(a.earnedDate)),
				render: (earnedDate) =>
					formatDate(
						earnedDate,
						this.props.currentUser.languageCode,
						this.props.currentUser.dateFormat
					),
			},
			{
				title: headers.payment,
				dataIndex: 'payment',
				key: 'payment',
				width: '9%',
				render: (payment, data) => (
					<>{this.getPaymentLink(payment, get(data, 'referralId'))}</>
				),
			},
			{
				title: headers.status,
				dataIndex: 'bonusStatus',
				key: 'bonusStatus',
				showSorterTooltip: false,
				sorter: (a, b) => sortByAlph(a.bonusStatus, b.bonusStatus),
				render: (bonusStatus, data) => {
					const overlayMenu = (
						<Menu key={data.id}>
							{Object.keys(BONUS_STATUS).map((key) => {
								return (
									<Menu.Item
										key={key}
										className={dropdownMenuItem}
										style={this.conditionalStatusStyle(key)}
									>
										<div onClick={(e) => e.stopPropagation()}>
											<Popconfirm
												key={key}
												title={
													<>
														<strong>
															{ml(
																'Confirm Updates?',
																this.props.currentUser,
																allMultiLingualData
															)}
														</strong>
														{data?.referralId && (
															<>
																<div
																	style={{
																		marginTop: '12px',
																		marginLeft: '-22px',
																	}}
																>
																	<strong>
																		<span style={{ color: 'red' }}>* </span>
																		{ml(
																			'Reason for Change',
																			currentUser,
																			allMultiLingualData
																		)}
																		:
																	</strong>
																</div>
																<Input.TextArea
																	value={this.state.reasonForChange}
																	className="custom-input"
																	placeholder={
																		ml(
																			'Provide a reason for change',
																			currentUser,
																			allMultiLingualData
																		) +
																		' ' +
																		'(' +
																		ml(
																			'required',
																			currentUser,
																			allMultiLingualData
																		) +
																		')'
																	}
																	style={{
																		width: '168px',
																		maxWidth: '168px',
																		marginTop: '2px',
																		marginLeft: '-22px',
																		resize: 'none',
																	}}
																	onChange={this.handleReasonForChange}
																	onClick={(e) => e.stopPropagation()}
																/>
															</>
														)}
													</>
												}
												overlayClassName={popoverStyles}
												placement="top"
												okText={ml('Yes', currentUser, allMultiLingualData)}
												cancelText={ml('No', currentUser, allMultiLingualData)}
												zIndex={1071}
												disabled={
													BONUS_STATUS[bonusStatus] === BONUS_STATUS[key]
												}
												onConfirm={() =>
													this.handleUpdateBonusStatus(
														key,
														data.id,
														get(data, 'referralId'),
														data.payment
													)
												}
											>
												{' '}
												{ml(
													BONUS_STATUS[key],
													this.props.currentUser,
													allMultiLingualData
												)}
											</Popconfirm>
										</div>
									</Menu.Item>
								);
							})}
						</Menu>
					);

					const key = data.id;

					return (
						<Dropdown
							key={data.id}
							trigger={['click']}
							style={{
								marginRight: 5,
							}}
							open={this.state.visibility[key]}
							overlay={overlayMenu}
							disabled={isStatusDropdownDisabled}
							onOpenChange={(flag) => this.handleVisibleChange(key, flag)}
						>
							<Button type="link">
								<span style={this.conditionalStatusStyle(bonusStatus)}>
									{ml(
										BONUS_STATUS[bonusStatus],
										this.props.currentUser,
										allMultiLingualData
									)}{' '}
								</span>
								<DownOutlined />
							</Button>
						</Dropdown>
					);
				},
			},
			{
				title: headers.details,
				key: 'details',
				render: (bonus) => {
					return (
						// If there is a bonus, allow user to open the bonus details modal
						<Button
							type="primary"
							size="small"
							onClick={() => this.setState({ detailsModalBonus: bonus })}
						>
							Details
						</Button>
					);
				},
			},
		];
		const locale = {
			emptyText: <Spinner company={company} />,
		};

		return (
			<>
				<Table
					pagination={{ pageSize: 25, showSizeChanger: false }}
					rowKey={(record) => record.id}
					dataSource={bonuses}
					columns={columns}
					locale={locale}
					scroll={{ x: 656 }}
					onChange={(_pagination, _filters, _sorter, extra) =>
						this.handleTableChange(extra)
					}
				/>
				{/* If there's a selected bonus, allow user to open the details. This ensures new state every modal open */}
				{detailsModalBonus && (
					<BonusDetailsModal
						bonus={detailsModalBonus}
						theme={theme}
						updateReferralBonusStatus={this.updateReferralBonusStatus}
						currentUser={this.props.currentUser}
						awaitingResponse={this.state.awaitingResponse}
						allMultiLingualData={allMultiLingualData}
						updateBonusInState={this.updateBonusInState}
						onUpdateBonus={this.props.onUpdateBonus}
						onUpdateReferral={this.props.onUpdateReferral}
						onClose={() => {
							this.setState({ detailsModalBonus: null });
						}}
					/>
				)}
			</>
		);
	}
}

export default withApollo(BonusTable);
