import { CloseOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Popover, Typography, message } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import { JobLocation } from 'src/_shared/index.js';
import { parse } from 'src/_shared/services/utils.js';
import { ImInterestedButton } from '../../../_shared/components/im-interested-send-button';
import { InviteToApplyButton } from '../../../_shared/components/invite-to-apply-send-button';
import { ReferralRequestModal } from '../../../_shared/referral-request-modal';
import { COLORS } from '../../../_shared/styles/colors';
import { closeButton, closeIcon } from '../referral-card/referralCardStyle.js';

const { Title } = Typography;
class BaseJobMatchCard extends Component {
	state = {
		isModalVisible: false,
	};

	createReferralRequestNotification = (jobId) => {
		const {
			employeeId,
			contactId,
			currentUserId,
			onActionButtonClick,
			notifications = [],
		} = this.props;
		const input = {
			type: 'referralRequested',
			userId: employeeId,
			requestingUserId: currentUserId,
			contactId,
			jobId,
			referralRequestedStatus: 'requested',
		};
		const existingNotification = notifications.find((notification) => {
			for (const key in input) {
				if (notification[key] !== input[key]) {
					return false;
				}
			}

			return true;
		});
		if (existingNotification) {
			message.warning(
				'A referral request has already been made for this employee, contact, and job combination.'
			);
			return;
		}

		return onActionButtonClick({ input })
			.then(() => {
				message.success('Referral requested');
				this.setState({
					isModalVisible: false,
				});
			})
			.catch((error) => {
				console.error(error);
				message.error('An error occurred while requesting a referral.');
			});
	};

	toggleModal = () => {
		const { isModalVisible } = this.state;
		this.setState({
			isModalVisible: !isModalVisible,
		});
	};

	render() {
		const {
			allMultiLingualData,
			currentUser,
			incompleteCareerProfile,
			onUpdateJob,
			onCreateReferral,
			allowSelfReferrals,
			allowSelfReferralsInternalLink,
			selectedEmployeeId,
			selectedEmailAddress,
		} = this.props;
		const contacts =
			get(this.props, 'contacts', []).length > 0
				? this.props.contacts
				: get(currentUser, 'contacts', []);
		const department = get(this.props, 'jobMatch.job.department');
		const internalJobLink = get(this.props, 'jobMatch.job.internalJobLink');
		const location = parse(get(this.props, 'jobMatch.job.location', '{}'));
		let locations = get(this.props, 'jobMatch.job.locations');
		locations = locations ? get(this.props, 'jobMatch.job.locations') : [];
		const theme = parse(get(this.props, 'currentUser.company.theme', '{}'));
		const isExtendedUser =
			get(this.props, 'currentUser.role') === 'extendedUser';
		const extendedContacts = get(
			this.props,
			'currentUser.extendedContactData',
			[]
		).map((contact) => get(contact, 'user'));

		const extendedNetworkUsers = extendedContacts.filter(
			(user) =>
				get(user, 'companyId') === get(this.props, 'jobMatch.job.companyId')
		);
		const subCompanyId = get(this.props, 'jobMatch.job.subCompanyId', null);
		let subCompanyName = get(this.props, 'jobMatch.job.subCompany.name');
		if (isExtendedUser && !subCompanyName)
			subCompanyName = get(this.props, 'jobMatch.job.company.name');
		let companyName = get(this.props, 'jobMatch.job.company.name');
		if (get(this.props, 'jobMatch.job.subCompany.name', '').length > 0)
			companyName = get(this.props, 'jobMatch.job.subCompany.name');

		const matchQuality = (relevance) => {
			switch (true) {
				case incompleteCareerProfile: {
					return (
						<Link to="/career">
							<Popover
								content="Update Career Profile for better matching"
								trigger="hover"
							>
								<div className="match-ribbon fair" />
							</Popover>
						</Link>
					);
				}

				case relevance >= 50: {
					return (
						<Popover content="Match Quality : Excellent" trigger="hover">
							<div className="match-ribbon excellent" />
						</Popover>
					);
				}

				case relevance >= 30: {
					return (
						<Popover content="Match Quality : Good" trigger="hover">
							<div className="match-ribbon good" />
						</Popover>
					);
				}

				default: {
					return (
						<Popover content="Match Quality : Fair" trigger="hover">
							<div className="match-ribbon fair" />
						</Popover>
					);
				}
			}
		};

		const showCompleteCareerProfileCard =
			get(this.props, 'jobMatch.id') === 'update';

		return showCompleteCareerProfileCard ? (
			<div
				style={isExtendedUser ? { borderRadius: '10px' } : {}}
				className="ant-card job-card"
			>
				<div
					className="ant-card-body"
					style={{ padding: isExtendedUser ? '25px' : '0', height: '100%' }}
				>
					<div className="job-card-inner">
						<div className="job-card-header">
							<div className="job-title-wrap">
								<h4
									className="job-title"
									style={{ flex: 1, textAlign: 'center', color: '#e75da7' }}
								>
									Would you like to see more?
								</h4>
							</div>
							<p style={{ textAlign: 'center' }}>
								Import a resume and update your career profile to see better job
								recommendations.
							</p>
						</div>
						<div className="job-card-footer">
							<Button
								ghost
								block
								type="primary"
								size="large"
								onClick={() => this.props.handleCareerProfile()}
							>
								Update Career Profile
							</Button>
						</div>
					</div>
				</div>
			</div>
		) : (
			<div
				style={isExtendedUser ? { borderRadius: '10px' } : {}}
				className="ant-card job-card"
			>
				{!isExtendedUser &&
					allowSelfReferrals &&
					!get(this.props, 'jobMatch.job.hideImInterested') &&
					((allowSelfReferralsInternalLink && internalJobLink) ||
						!allowSelfReferralsInternalLink) &&
					!get(this.props, 'jobMatch.temporary') && (
						<Popconfirm
							title="Remove this match？"
							placement="top"
							okText="Yes"
							cancelText="No"
							onConfirm={() =>
								this.props.notInterested(get(this.props, 'jobMatch'))
							}
						>
							<span className={closeButton}>
								<CloseOutlined className={closeIcon} />
							</span>
						</Popconfirm>
					)}
				{matchQuality(get(this.props, 'jobMatch.relevance'))}
				<div
					className="ant-card-body"
					style={{ padding: isExtendedUser ? '25px' : '0', height: '100%' }}
				>
					<div className="job-card-inner">
						<div className="job-card-header">
							<div className="job-title-wrap">
								<Title ellipsis className="job-title" level={4}>
									<Link
										to={`/browsejobs/${get(this.props, 'jobMatch.job.id')}`}
									>
										{get(this.props, 'jobMatch.job.title')}
									</Link>
								</Title>
							</div>
							<div className="job-info-wrap">
								<ul className="job-info">
									<JobLocation
										allMultiLingualData={allMultiLingualData}
										currentUser={currentUser}
										location={location}
										locations={locations}
									/>
									{!isExtendedUser && (
										<li>
											<i className="icon-folder" />
											<span>{get(department, 'name', '')}</span>
										</li>
									)}
									{subCompanyName &&
										(!currentUser.company.hideSubcompanyOnJobs ||
											currentUser?.role !== 'employee') && (
											<li>
												<i className="icon-building" />
												<span>{subCompanyName}</span>
											</li>
										)}
								</ul>
							</div>
						</div>
						<div className="job-card-footer">
							{isExtendedUser && (
								<div
									style={{
										textAlign: 'center',
										color: COLORS.lightGray4,
										height: 40,
										marginTop: 10,
										marginBottom: -10,
									}}
								>
									You are connected to{' '}
									<strong style={{ color: COLORS.gray }}>
										{extendedNetworkUsers.length}
									</strong>{' '}
									{extendedNetworkUsers.length === 1 ? 'employee' : 'employees'}
									.
								</div>
							)}
							{isExtendedUser && (
								<ReferralRequestModal
									allMultiLingualData={allMultiLingualData}
									extendedNetworkUsers={extendedNetworkUsers}
									contacts={get(this.props, 'currentUser.contacts')}
									job={get(this.props, 'jobMatch.job')}
									referrals={get(this.props, 'jobMatch.job.referrals')}
								/>
							)}

							{!isExtendedUser &&
								allowSelfReferrals &&
								!get(this.props, 'jobMatch.job.hideImInterested') &&
								((allowSelfReferralsInternalLink && internalJobLink) ||
									!allowSelfReferralsInternalLink) &&
								!get(this.props, 'hideImInterested') && (
									<ImInterestedButton
										key={get(this.props, 'jobMatch.job.id')}
										allMultiLingualData={allMultiLingualData}
										contacts={contacts}
										currentUser={currentUser}
										internalJobLink={internalJobLink}
										job={get(this.props, 'jobMatch.job')}
										onCreateReferral={onCreateReferral}
									/>
								)}
							{get(this.props, 'hideImInterested') && (
								<InviteToApplyButton
									key={get(this.props, 'jobMatch.job.id')}
									allMultiLingualData={allMultiLingualData}
									contacts={contacts}
									currentUser={currentUser}
									internalJobLink={internalJobLink}
									job={get(this.props, 'jobMatch.job')}
									selectedEmployeeId={selectedEmployeeId}
									selectedEmailAddress={selectedEmailAddress}
									onCreateReferral={onCreateReferral}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export const JobMatchCard = withApollo(BaseJobMatchCard);
